/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import React /* useRef */ from "react";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import PlanosSobral from "./sections/PlanosSobral";
import TitlePlanosSobral from "pages/Presentation/sections/TitlePlanosSobral";
/* import Pages from "pages/Presentation/sections/Pages"; */
import Testimonials from "pages/Presentation/sections/Testimonials";
import Mapa from "pages/Presentation/sections/Mapa";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
/* import bgImage from "assets/images/bg-presentation.jpg"; */
import backgroundVideo from "assets/images/bgvideo.mp4";
import WhatsIcon from "assets/svg/whats";
import BarCode from "assets/svg/barcode";
import PlayStore from "assets/svg/playstore";
/* import TitlePlanosMoveis from "./sections/TitlePlanosMoveis";
import PlanosMoveis from "./sections/PlanosMoveis"; */
/* import RegrasṔromo from "./sections/RegrasPromo";
import TitlePromo from "./sections/TitlePromo"; */
/* import TitlePromoSJ from "./sections/TitlePromoSJ";
import RegrasPromoSJ from "./sections/RegrasPromoSJ"; */

function Presentation() {
  /*   const targetRef = useRef(null); */

  // Função para rolar para o elemento alvo
  /*   const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }; */
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://api.whatsapp.com/send?phone=558009111000&text=Quero ser cliente",
          label: "Contrate já",
          color: "info",
        }}
        /*         action2={{
          type: "internal",
          funcRef: scrollToTarget(),
          label: "REGRAS PROMOÇÃO",
          color: "error",
        }} */
        sticky
      />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        >
          <source src={backgroundVideo} type="video/mp4" />
          {/* Adicione mais sources se necessário para compatibilidade com diferentes navegadores */}
        </video>
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            />

            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -5 /* tamanho do cardprincipal */,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <TitlePlanosSobral />
        <PlanosSobral />
        {/*         <TitlePlanosMoveis />
        <PlanosMoveis /> */}
        {/*   <Pages /> */}
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon={<WhatsIcon />}
                title="Atendimento Via WhatsApp"
                description="Receba suporte rápido e soluções eficientes para sua internet na SobralNet através do WhatsApp."
                action={{
                  type: "external",
                  route: "https://api.whatsapp.com/send?phone=558009111000&text=Comercial",
                  label: "Inicie o Atendimento",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon={<BarCode />}
                title="2ª Via de Boleto"
                description="Obtenha sua 2ª via de boleto de forma simples e rápida na SobralNet pelo WhatsApp."
                action={{
                  type: "external",
                  route: "https://api.whatsapp.com/send?phone=558009111000&text=Fatura SOBRALNET",
                  label: "Acesse o Boleto",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon={<PlayStore />}
                title="App do Cliente"
                description="Acesse facilmente sua conta e gerencie serviços com o App do Cliente SobralNet, de forma prática e intuitiva."
                action={{
                  type: "external",
                  route: "https://play.google.com/store/apps/details?id=com.portal.sobralnet&pli=1",
                  label: "Baixe o App",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <Testimonials />
        {/*         <div ref={targetRef}>
          <TitlePromo />
        </div>
        <RegrasṔromo /> */}
        {/*         <TitlePromoSJ />
        <RegrasPromoSJ /> */}
        <Mapa />
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Agradeço pela sua visita ao nosso site!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Fique por dentro das nossas novidades ao acompanhar as nossas redes sociais!
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://www.instagram.com/netsobral/"
                  target="_blank"
                  color="instagram"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-instagram" />
                  &nbsp;Instagram
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/SobralNetFibraOptica"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Facebook
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://api.whatsapp.com/send?phone=558009111000&text=Quero ser cliente"
                  target="_blank"
                  color="slack"
                >
                  <i className="fab fa-whatsapp" />
                  &nbsp;WhatsApp
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
